import React from "react";
import Base from "../Base";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Layout,
  Radio,
  Pagination,
  Spin,
  Col,
  Button,
  Space,
  List,
  Avatar,
} from "antd";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class ShowsList extends Base {
  static MediaTypes = [
    { key: "-1", name: "shows.type.all" },
    { key: "0", name: "shows.type.text" },
    { key: "1", name: "shows.type.image" },
    { key: "2", name: "shows.type.video" },
  ];

  static MediaStatus = [
    { key: "-1", name: "shows.status.all" },
    { key: "1", name: "shows.status.valid" },
    { key: "0", name: "shows.status.invalid" },
  ];

  static ShowsSorts = [
    { key: "created_at@desc", name: "shows.sortTypes.created_at_desc" },
    { key: "created_at@asc", name: "shows.sortTypes.created_at_asc" },
  ];

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      mediaType: this.getRouterQueryItemByKey(
        ShowsList.MediaTypes,
        "mediaType"
      ),
      showStatus: this.getRouterQueryItemByKey(
        ShowsList.MediaStatus,
        "showStatus"
      ),
      showSort: this.getRouterQueryItemByKey(ShowsList.ShowsSorts, "showSort"),

      categories: null,
      currentCat: { id: -1, zh_name: "全部", ug_name: "ھەممىسى" },

      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),

      data: null,
      loadingData: false,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(this.i18n.t("order.loadingData"), Base.Message.loading);
      await this.getCategories();
      await this.loadShowsList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevProps.search !== this.props.search ||
      prevState.mediaType.key !== this.state.mediaType.key ||
      prevState.currentCat.id !== this.state.currentCat.id ||
      prevState.showStatus.key !== this.state.showStatus.key ||
      prevState.showSort.key !== this.state.showSort.key ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize
    ) {
      this.showMessage(this.i18n.t("order.updatingData"), Base.Message.loading);
      this.loadShowsList();
      this.setRouterQuery({
        search: this.props.search ?? "",
        mediaType: this.state.mediaType.key,
        showStatus: this.state.showStatus.key,
        showSort: this.state.showSort.key,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  async getCategories() {
    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.shows.category;
    let apiConfig = {
      token,
    };

    try {
      let res = await api.run(apiConfig);
      this.setState({
        categories: [{ id: -1, zh_name: "全部", ug_name: "ھەممىسى" }].concat(
          res.data
        ),
      });
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    }
  }

  async loadShowsList() {
    if (this.state.loadingData) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.shows.list;
    let apiConfig = {
      token: token,
      query: {
        keyword: this.props.search ?? "",
        type: this.state.mediaType.key,
        status: this.state.showStatus.key,
        category_id: this.state.currentCat.id,
        sort: this.state.showSort.key,
        page: this.state.currentPage,
        count: this.state.currentPageSize,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingData: false });
    }
  }

  handleFilterCatChange = (e) => {
    const { categories } = this.state;
    let currentCat = categories.find((type) => type.id === e.target.value);
    this.setState({ currentCat, currentPage: 1 });
  };

  handleFilterMediaTypeChange = (e) => {
    let type = ShowsList.MediaTypes.find((type) => type.key === e.target.value);
    this.setState({ mediaType: type, currentPage: 1 });
  };

  handleFilterShowsStatusChange = (e) => {
    let type = ShowsList.MediaStatus.find(
      (type) => type.key === e.target.value
    );
    this.setState({ showStatus: type, currentPage: 1 });
  };

  handleFilterOrderSortChange = (e) => {
    let sort = ShowsList.ShowsSorts.find((sort) => sort.key === e.target.value);
    this.setState({ showSort: sort, currentPage: 1 });
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  onGo2Edit = (show) => {
    const { categories } = this.state;

    categories.shift();

    const newShow = {
      ...show,
      media: show.type === 2 ? null : show.media,
      video: show.type === 2 ? show.media : null,
    };

    this.props.history.push({
      pathname: "/main/shows/edit",
      state: {
        edit: true,
        categories,
        show: newShow,
      },
    });
  };

  onGo2Add = () => {
    const { categories } = this.state;
    if (!categories) return;

    categories.shift();

    this.props.history.push({
      pathname: "/main/shows/edit",
      state: {
        categories,
      },
    });
  };

  onDeleteThisShow = (showsId) => {
    if (!showsId) return;

    this.showModal(
      this.i18n.t("shows.deleteConfirm"),
      Base.Modal.confirm,
      async () => {
        let token = this.getUserToken();
        if (!token) return;

        let api = this.api.shows.delete;
        let apiConfig = {
          token,
          path: {
            showsId,
          },
        };

        try {
          let res = await api.run(apiConfig);

          if (!this.mounted) return;

          if (res.result) {
            this.showMessage(res.message, Base.Message.success);
            this.loadShowsList();
          } else {
            this.showMessage(res.message, Base.Message.error);
          }
        } catch (error) {
          if (!this.mounted) return;
          this.handleApiError(api, error);
        }
      }
    );
  };

  _renderFiltersMediaType() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("shows.mediaTypesTitle")}</span>
        <Radio.Group
          value={this.state.mediaType.key}
          onChange={this.handleFilterMediaTypeChange}
        >
          {ShowsList.MediaTypes.map((type, index) => {
            return (
              <Radio key={`paytype-${index}`} value={type.key}>
                {this.i18n.t(type.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersCategories() {
    const { categories } = this.state;
    if (!categories) return null;

    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("shows.categories")}</span>
        <Radio.Group
          value={this.state.currentCat.id}
          onChange={this.handleFilterCatChange}
        >
          {categories.map((cat, index) => {
            return (
              <Radio key={`paytype-${index}`} value={cat.id}>
                {this.i18n.getPropNameByLocale(cat, "name")}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersShowsStatus() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("shows.orderTypesTitle")}</span>
        <Radio.Group
          value={this.state.showStatus.key}
          onChange={this.handleFilterShowsStatusChange}
        >
          {ShowsList.MediaStatus.map((type, index) => {
            return (
              <Radio key={`ordertype-${index}`} value={type.key}>
                {this.i18n.t(type.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersShowsSort() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("shows.sortTypesTitle")}</span>
        <Radio.Group
          value={this.state.showSort.key}
          onChange={this.handleFilterOrderSortChange}
        >
          {ShowsList.ShowsSorts.map((sort, index) => {
            return (
              <Radio key={`orderSort-${index}`} value={sort.key}>
                {this.i18n.t(sort.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFilters() {
    return (
      <Col className="filter-container">
        {this._renderFiltersMediaType()}
        {this._renderFiltersShowsStatus()}
        {this._renderFiltersCategories()}
        {this._renderFiltersShowsSort()}
      </Col>
    );
  }

  _renderListShowsPagination() {
    let data = this.state.data;
    let shows = data ? data.data : null;
    if (shows && shows.length > 0) {
      return (
        <div className="list-pagination">
          <Pagination
            total={data.total}
            current={this.state.currentPage}
            showSizeChanger
            showQuickJumper
            pageSizeOptions={["10", "20", "30"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  getCover(show) {
    if (show.type === 1) {
      return show.media[0] && show.media[0].url;
    }

    if (show.type === 2) {
      return show.video_cover && show.video_cover.url;
    }

    return "";
  }

  formatTags(tags) {
    if (Array.isArray(tags) && tags.length > 0) {
      return `#${tags.join(" #")}`;
    }
    return this.i18n.t("shows.noTags");
  }

  _renderListShows() {
    let { data } = this.state;
    if (data) {
      if (data.data && data.data.length > 0) {
        return [
          <div key="order-list-header" className="col-box todays-order">
            <div className="box-header">
              <span className="title">
                {this.i18n.t("shows.showsTitleCount", {
                  total: data.total,
                })}
              </span>
              <Button
                onClick={this.onGo2Add}
                className="add-btn"
                icon={<PlusOutlined />}
                size="small"
              >
                {this.i18n.t("shows.add")}
              </Button>
            </div>
          </div>,
          <Spin key="order-list-spin" spinning={this.state.loadingData}>
            <List
              bordered
              style={{ backgroundColor: "#fff" }}
              dataSource={data.data}
              renderItem={(show) => {
                return (
                  <List.Item key={show.id}>
                    <List.Item.Meta
                      avatar={<Avatar src={this.getCover(show)} size="large" />}
                      title={this.formatTags(show.product_tags)}
                      description={show.content}
                    />
                    <Space direction="horizontal" size={10}>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => this.onGo2Edit(show)}
                        type="link"
                      />

                      <Button
                        icon={<DeleteOutlined />}
                        type="link"
                        danger
                        onClick={() => this.onDeleteThisShow(show.id)}
                      />
                    </Space>
                  </List.Item>
                );
              }}
            ></List>
          </Spin>,
        ];
      } else {
        return (
          <div className="col-box todays-order">
            <div className="box-header">
              <span className="title">{this.i18n.t("shows.showsTitle")}</span>
              <Button
                onClick={this.onGo2Add}
                className="add-btn"
                icon={<PlusOutlined />}
                size="small"
              >
                {this.i18n.t("shows.add")}
              </Button>
            </div>
            <div className="box-container">
              <h4>{this.i18n.t("shows.loadingShowEmpty")}</h4>
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="col-box todays-order">
            <div className="box-header">
              <span className="title">{this.i18n.t("shows.showsTitle")}</span>
              <Button
                onClick={this.onGo2Add}
                className="add-btn"
                icon={<PlusOutlined />}
                size="small"
              >
                {this.i18n.t("shows.add")}
              </Button>
            </div>
            <div className="box-container">
              <h4>{this.i18n.t("shows.loadingShow")}</h4>
            </div>
          </div>
        );
      }

      return (
        <div className="col-box todays-order">
          <div className="box-header">
            <span className="title">{this.i18n.t("shows.showsTitle")}</span>
            <Button
              onClick={this.onGo2Add}
              className="add-btn"
              icon={<PlusOutlined />}
              size="small"
            >
              {this.i18n.t("shows.add")}
            </Button>
          </div>
          <div className="box-container">
            <h4>{this.i18n.t("shows.loadingShowEmpty")}</h4>
          </div>
        </div>
      );
    }
  }

  _renderListContainer() {
    return (
      <div className="list-container">
        <div className="list-wrap">
          {this._renderListShows()}
          {this._renderListShowsPagination()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout id="route-order-list">
        {this._renderFilters()}
        {this._renderListContainer()}
      </Layout>
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(ShowsList);
