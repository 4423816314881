import { Spin, Table } from "antd";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./QrcodeList.scss";
import { qrcodeListColumns } from "./qrcodeListColumns";
import QrcodeListHeader from "./QrcodeListHeader";
import { useHistory } from "react-router-dom";
import breadCrumbDefault from "./breadCrumb";

import { generateExcel, generateQrcode } from "./qrcodeApi";
const i18n = Locale.getInstance();

const QrcodeList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { data, error, isLoading } = useQuery(
    ["lottery-qrcode", user.token],
    () =>
      getList("lottery-qrcode", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
        },
        token: user.token,
      })
  );

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const generateQrcodeMutation = useMutation(generateQrcode, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const generateExcelMutation = useMutation(generateExcel, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  return (
    <>
      <BreadCrumb data={breadCrumbDefault} />
      <div className="qrcode">
        <QrcodeListHeader keyword={keyword} setKeyword={setKeyword} />
        <Table
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          columns={qrcodeListColumns(
            deleteMutation,
            generateQrcodeMutation,
            generateExcelMutation,
            user.token,
            setLoading,
            history
          )}
          dataSource={data?.data}
          scroll={{
            y: 320,
          }}
          pagination={{
            onChange: handleChangePage,
            total: data?.total,
          }}
        />
      </div>
    </>
  );
};

export default QrcodeList;
