import { Button, Form, Input, Radio, Spin, Table } from "antd";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./TeacherList.scss";
import { teacherListColumns } from "./teacherListColumns";
import TeacherListHeader from "./TeacherListHeader";
import { useHistory } from "react-router-dom";
const i18n = Locale.getInstance();

const TeacherList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { data, error, isLoading } = useQuery(
    ["course-teachers", user.token],
    () =>
      getList("course/teachers", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
        },
        token: user.token,
      })
  );

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-teachers");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const breadCrumbData = [
    {
      href: "/main/courses/article-courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/courses/teachers",
      title: i18n.t("course.teacher.title"),
    },
  ];

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }

  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="teacher">
        <TeacherListHeader keyword={keyword} setKeyword={setKeyword} />
        <Table
          className="list"
          rowKey="id"
          columns={teacherListColumns(
            deleteMutation,
            user.token,
            setLoading,
            history
          )}
          dataSource={data?.data}
          scroll={{
            y: 320,
          }}
          pagination={{
            onChange: handleChangePage,
            total: data?.total,
          }}
        />
      </div>
    </>
  );
};

export default TeacherList;
