import { Button, Space, Spin, Table } from "antd";
import { useState } from "react";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";
import { getList } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";

import "./CourseSelector.scss";

const i18n = Locale.getInstance();

const CourseSelector = ({ setSelectedLink, selectedLink }) => {
  const user = useSelector((state) => getUserInfo(state));
  const [page, setPage] = useState(1);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const { data, error, isLoading } = useQuery(["courses", user.token], () =>
    getList("courses", {
      pagination: {
        perPage: 10,
        page,
      },
      sort: {
        field: "id",
        order: "desc",
      },
      query: {
        status: 1,
      },

      token: user.token,
    })
  );

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: i18n.t("course.name"),
      render: (item) => {
        return (
          <>
            <Space className="course-name">
              <Space direction="vertical">
                <img
                  src={i18n.getPropNameByLocale(item, "cover")}
                  alt={i18n.getPropNameByLocale(item, "name")}
                  width={60}
                  height={60}
                />
                <span>{i18n.getPropNameByLocale(item, "name")}</span>
              </Space>
            </Space>
          </>
        );
      },
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: i18n.t("product.cats.actions"),
      width: 100,
      render: (item) => {
        return (
          <>
            <Button
              type={item.id === selectedLink.key ? "primary" : "default"}
              onClick={() => {
                setSelectedLink({
                  key: item.id,
                  image: i18n.getPropNameByLocale(item, "cover"),
                  ug_name: `دەرس ~${item.ug_name}`,
                  zh_name: `课程~${item.zh_name}`,
                  url: `/course/detail/index?courseId=${item.id}`,
                });
              }}
              size="small"
            >
              {item.id === selectedLink.key
                ? i18n.t("link.selected")
                : i18n.t("link.select")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="courses">
          <Table
            className="list"
            rowKey="id"
            columns={columns}
            dataSource={data?.data}
            pagination={{
              onChange: handleChangePage,
              total: data?.total,
            }}
            scroll={{
              y: 320,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default CourseSelector;
