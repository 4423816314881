import { Spin, Table } from "antd";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";

import "./CourseList.scss";
import { courseListColumns } from "./courseListColumns";
import CourseListHeader from "./CourseListHeader";

const CourseList = ({ type }) => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChangePage = (newPage) => {
    console.log("🚀 ~ handleChangePage ~ newPage:", newPage);
    setPage(newPage);
  };

  const { data, error, isLoading } = useQuery(
    ["courses", user.token, page],
    () =>
      getList("courses", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
          type: type,
        },
        token: user.token,
      })
  );
  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  return (
    <>
      <Spin spinning={loading || isLoading}>
        <CourseListHeader
          keyword={keyword}
          setKeyword={setKeyword}
          type={type}
        />
        <Table
          className="list"
          rowKey="id"
          columns={courseListColumns(
            deleteMutation,
            user.token,
            setLoading,
            history,
            type
          )}
          dataSource={data?.data}
          scroll={{
            y: 600,
          }}
          pagination={{ onChange: handleChangePage, total: data?.total }}
        />
      </Spin>
    </>
  );
};

export default CourseList;
