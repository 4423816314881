import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Space,
  Select,
  Card,
  InputNumber,
  Tag,
  Spin,
  Avatar,
  message,
  DatePicker,
  Layout,
  Divider,
  Input,
  Upload,
  Switch,
  List,
  Radio,
  Cascader,
} from "antd";
import { debounce } from "lodash";
import {
  CheckCircleTwoTone,
  PlusOutlined,
  LeftOutlined,
  UploadOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import moment from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import { regionData, codeToText } from "element-china-area-data";

import { Locale, ApiManager, Util } from "../../utils";
import { JD_ID } from "../../constants";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import TranslateBtn from "../../components/product/TranslateBtn";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import { getList } from "../../utils/http/dataProvider";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const api = ApiManager.getInstance();
const base = Base.getInstance();
export const TYPES = {
  PRODUCT: 0,
  RED: 1,
  THANKS: 2,
  RED_PACKET: 3,
  PRODUCT_ON_SITE: 4,
  POINT: 5,
};
const areas = {
  wheel: { value: "wheel", label: i18n.t("lottery.wheel"), max: 10, min: 6 },
  nine: { value: "nine", label: i18n.t("lottery.nine"), max: 8, min: 8 },
  fruit: { value: "fruit", label: i18n.t("lottery.fruit"), max: 6, min: 3 },
};

export const lotteryPrizeTypes = [
  { name: i18n.t("lottery.product"), value: TYPES.PRODUCT, color: "geekblue" },
  { name: i18n.t("lottery.red"), value: TYPES.RED, color: "volcano" },
  { name: i18n.t("lottery.thanks"), value: TYPES.THANKS, color: "green" },
  { name: i18n.t("qrcode.redPacket"), value: TYPES.RED_PACKET, color: "red" },
  {
    name: i18n.t("qrcode.productOnSite"),
    value: TYPES.PRODUCT_ON_SITE,
    color: "blue",
  },
  { name: i18n.t("qrcode.point"), value: TYPES.POINT, color: "black" },
];

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}
export const braftUpload = async (param) => {
  const user = useSelector((state) => getUserInfo(state));
  const token = user.token;
  if (token) {
    const uploadFiles = [{ file: param.file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "article-content",
    };
    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        param.success({
          url: res.data[0].url,
        });
      } else {
        param.error({
          msg: res.message,
        });
      }
    } catch (error) {
      param.error({
        msg: error.message,
      });
    }
  }
};
const LotteryEdit = () => {
  const [form] = Form.useForm();
  const onSearchProduct = useRef();
  const onGetDataList = useRef();
  const [lotteryQrcode, setLotteryQrcode] = useState(null);
  const [themeId, setThemeId] = useState({ id: null, type: null });
  const [themeType, setThemeType] = useState(0);
  const [themeList, setThemeList] = useState({ data: [] });
  const user = useSelector((state) => getUserInfo(state));
  const settings = useSelector((state) => getMerchantSettings(state));
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState({ data: [] });
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { data: qrcodes } = useQuery(
    ["lottery-qrcode-activity", user.token],
    () =>
      getList("lottery-qrcode-activity", {
        pagination: {
          perPage: 100,
          page: 1,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        token: user.token,
      })
  );

  onSearchProduct.current = debounce(
    async (keyword, search_type = "keyword") => {
      let customApi = api.product.products;
      if (user && user.merchant.id === JD_ID) {
        customApi = api.product.jdProducts;
      }
      if (user && user.token) {
        const query = {
          category_id: -1,
          page: 1,
          keyword,
          search_type,
          count: 5,
        };
        const apiConfig = {
          token: user.token,
          query,
        };
        try {
          setFetching(true);
          const res = await customApi.run(apiConfig);
          if (res.result) {
            setProductList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          console.log("loadProducts -> error", error);
        } finally {
          setFetching(false);
        }
      }
    },
    500
  );

  const getFormattedDateString = (date) => {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  };

  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const { token } = user;
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "lottery",
    };

    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  const onSave2Local = () => {
    const vals = form.getFieldsValue();
    storage.setItem(
      "lotteryData",
      JSON.stringify({
        ...vals,
        zh_description: vals.zh_description && vals.zh_description.toHTML(),
        ug_description: vals.ug_description && vals.ug_description.toHTML(),
        theme_id: themeId,
      })
    );
    history.goBack();
  };

  const onFinish = async (values) => {
    if (!themeId.id) {
      base.showModal(i18n.t("lottery.themeWarning"));
      return;
    }
    if (user.token && values.items && values.items.length > 0) {
      if (themeId.type === 0 && values.items.length < areas.wheel.min) {
        base.showModal(i18n.t("lottery.wheelWarning"));
        return;
      }
      if (themeId.type === 1 && values.items.length < areas.nine.min) {
        base.showModal(i18n.t("lottery.wheelWarning"));
        return;
      }
      if (themeId.type === 2 && values.items.length < areas.fruit.min) {
        base.showModal(i18n.t("lottery.fruitWarning"));
        return;
      }
      let apiUrl = api.lottery.add;
      const {
        zh_name,
        ug_name,
        valid_time,
        zh_description,
        ug_description,
        ticket_price,
        status,
        expense_points,
        qrcode_activity_id,
        priority,
        region_id,
        user_buy_ticket,
        exchange_on_site,
      } = values;
      const apiConfig = {
        token: user.token,
        data: {
          start_time: getFormattedDateString(values.range[0]),
          end_time: getFormattedDateString(values.range[1]),
          zh_name,
          ug_name,
          priority,
          region_id: region_id ? region_id[region_id.length - 1] : null,
          expense_points,
          qrcode_activity_id,
          valid_time,
          theme_id: themeId.id,
          type: themeId.type,
          ticket_price: ticket_price * 100,
          status: status ? 1 : 0,
          user_buy_ticket: user_buy_ticket ? 1 : 0,
          exchange_on_site: exchange_on_site ? 1 : 0,
          zh_description: zh_description && zh_description.toHTML(),
          ug_description: ug_description && ug_description.toHTML(),
          prizes: values.items.map((i) => {
            const tmp = {};
            const {
              zh_name,
              zh_image,
              ug_name,
              ug_image,
              itemType,
              quantity,
              is_special_prize,
              id,
              order,
              ship_price,
              zh_award_image,
              ug_award_image,
            } = i;
            tmp.type = itemType;
            tmp.quantity = quantity;
            tmp.is_special_prize = is_special_prize;
            tmp.zh_name = zh_name;
            tmp.ug_name = ug_name;
            tmp.order = order;
            tmp.ship_price = ship_price * 100;
            if (itemType === TYPES.PRODUCT) {
              tmp.product_id = i.productItem[0];
            }
            if (itemType === TYPES.PRODUCT_ON_SITE) {
              tmp.product_id = i.productItem[0];
            }
            if (itemType === TYPES.RED) {
              tmp.lucky_money_amount = i.redItem * 100;
            }
            if (itemType === TYPES.RED_PACKET) {
              tmp.lucky_money_amount = i.redItem * 100;
            }
            if (itemType === TYPES.POINT) {
              tmp.point_amount = i.pointItem;
            }
            if (
              zh_image &&
              zh_image[0] &&
              zh_image[0].uid !== -1 &&
              zh_image[0].response
            ) {
              tmp.zh_image = zh_image[0].response.path;
            }
            if (
              ug_image &&
              ug_image[0] &&
              ug_image[0].uid !== -1 &&
              ug_image[0].response
            ) {
              tmp.ug_image = ug_image[0].response.path;
            }
            if (
              zh_award_image &&
              zh_award_image[0] &&
              zh_award_image[0].uid !== -1 &&
              zh_award_image[0].response
            ) {
              tmp.zh_award_image = zh_award_image[0].response.path;
            }
            if (
              ug_award_image &&
              ug_award_image[0] &&
              ug_award_image[0].uid !== -1 &&
              ug_award_image[0].response
            ) {
              tmp.ug_award_image = ug_award_image[0].response.path;
            }
            if (typeof id === "number") {
              tmp.id = id;
            }
            return tmp;
          }),
        },
      };
      const { state } = location;
      if (state.data && state.data.id && state.action !== "copy") {
        apiUrl = api.lottery.update;
        apiConfig.path = { id: state.data.id };
      }
      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          history.replace({ pathname: "/main/lottery/list" });
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    } else {
      base.showModal(i18n.t("lottery.empty"), Base.Modal.warning);
    }
  };

  onGetDataList.current = debounce(async (page = 1) => {
    let themeApi = api.lottery.theme;
    if (user && user.token) {
      const query = {
        page,
        count: 10,
        "filter[type]": themeType,
      };
      const apiConfig = {
        token: user.token,
        query,
      };
      try {
        setFetching(true);
        const res = await themeApi.run(apiConfig);
        if (res.result) {
          setThemeList(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        console.log("loadProducts -> error", error);
      } finally {
        setFetching(false);
      }
    }
  }, 500);

  useEffect(() => {
    onGetDataList.current();
  }, [themeType]);

  useEffect(() => {
    let { data, action } = location.state;
    if (action === "local") {
      storage.getItem("lotteryData").then((localData) => {
        const { range, ug_description, zh_description, theme_id, ...others } =
          JSON.parse(localData);
        setThemeId(theme_id);
        setThemeType(theme_id.type);
        form.setFieldsValue({
          ...others,
          range:
            range?.length > 0
              ? [moment(range[0]), moment(range[1])]
              : [moment(), moment()],
          zh_description: BraftEditor.createEditorState(zh_description),
          ug_description: BraftEditor.createEditorState(ug_description),
        });
      });
      return;
    }
    let getDefaultImages = util.getDefaultImages;
    if (action === "copy") {
      getDefaultImages = util.getDefaultImagesForCopy;
    }
    console.log(
      "🚀 ~ file: LotteryEdit.jsx:422 ~ storage.getItem ~ localData:",
      data
    );
    if (data) {
      const {
        plate_background,
        ug_description,
        zh_description,
        theme_id,
        region_id,
        ...others
      } = data;
      let range = [];
      if (data?.start_time) {
        range[0] = data.start_time;
      }
      if (data?.end_time) {
        range[1] = data.end_time;
      }
      form.setFieldsValue({
        ...others,
        region_id: region_id
          ? [
              codeToText[`${region_id}`.slice(0, 2)],
              codeToText[`${region_id}`.slice(0, 4)],
              codeToText[`${region_id}`],
            ]
          : [],
        range:
          range?.length > 0
            ? [moment(range[0]), moment(range[1])]
            : [moment(), moment()],
        zh_description: BraftEditor.createEditorState(zh_description),
        ug_description: BraftEditor.createEditorState(ug_description),
        plate_background: getDefaultImages(data.plate_background),
      });
      if (data.activity) {
        setLotteryQrcode(data.activity);
      }
      setThemeId({ id: data.theme_id, type: data.type });
      setThemeType(data.type);
      if (data.prizes && Array.isArray(data.prizes) && data.prizes.length > 0) {
        form.setFieldsValue({
          items: data.prizes.map((i) => {
            const {
              zh_name,
              ug_name,
              zh_image,
              ug_image,
              zh_award_image,
              ug_award_image,
              type,
              product_id,
              lucky_money_amount,
              point_amount,
              is_special_prize,
              quantity,
              id,
              order,
              ship_price,
            } = i;
            return {
              zh_image: getDefaultImages(zh_image),
              ug_image: getDefaultImages(ug_image),
              zh_award_image: getDefaultImages(zh_award_image),
              ug_award_image: getDefaultImages(ug_award_image),
              zh_name,
              ug_name,
              itemType: type,
              is_special_prize,
              productItem: [product_id],
              quantity,
              redItem: lucky_money_amount ? lucky_money_amount / 100 : 0,
              pointItem: point_amount,
              id: action === "copy" ? `copy-${id}` : id,
              order,
              ship_price: ship_price / 100,
            };
          }),
        });
      }
    }
  }, [location.state, form]);

  let { data } = location.state;

  return (
    <Layout id="route-product-edit">
      <Layout.Header className="edit-header">
        <Button
          type="link"
          className="back"
          onClick={() => {
            history.goBack();
          }}
        >
          <LeftOutlined />
          {i18n.t("productEdit.headerBack")}
        </Button>
        <Divider type="vertical" />
        <span className="title">{i18n.t("sales.activity.add")}</span>
      </Layout.Header>
      <Layout.Content className="edit-content">
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            backgroundColor: "#fff",
          }}
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("sales.zhTitle")}
                name="zh_name"
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: i18n.t("sales.zhTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("sales.ugTitle")}
                name="ug_name"
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: i18n.t("sales.ugTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label={i18n.t("sales.activity.time")}
            name="range"
            wrapperCol={{ span: 10 }}
            rules={[
              { required: true, message: i18n.t("sales.gift.rangeWarning") },
            ]}
          >
            <DatePicker.RangePicker
              showTime
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item
            label={i18n.t("qrcode.priority")}
            name="priority"
            wrapperCol={{ span: 5 }}
            extra={i18n.t("qrcode.priorityRule")}
          >
            <InputNumber min={0} />
          </Form.Item>
          {settings.lottery_shop_type == 5 && (
            <>
              <Form.Item
                label={i18n.t("qrcode.expensePoints")}
                name="expense_points"
                wrapperCol={{ span: 5 }}
                extra={i18n.t("qrcode.expensePointsRule")}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item
                name="qrcode_activity_id"
                label={i18n.t("qrcode.selectQrcode")}
              >
                {lotteryQrcode ? (
                  <>
                    <>{i18n.getPropNameByLocale(lotteryQrcode, "name")}</>
                    <Button
                      type="link"
                      onClick={() => {
                        setLotteryQrcode();
                      }}
                    >
                      {i18n.t("member.reselect")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Select
                      style={{ width: 120 }}
                      onChange={(v) => {
                        form.setFieldsValue({ qrcode_activity_id: v });
                      }}
                    >
                      {qrcodes?.data.map((qrcode) => {
                        return (
                          <Select.Option key={qrcode.id} value={qrcode.id}>
                            {i18n.getPropNameByLocale(qrcode, "name")}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <>
                      <Button
                        onClick={() => {
                          queryClient.invalidateQueries(
                            "lottery-qrcode-activity"
                          );
                        }}
                        type="link"
                      >
                        {i18n.t("common.refresh")}
                      </Button>
                    </>
                    <>
                      <a
                        href="/main/lottery-qrcode-activity/list"
                        target="_blank"
                      >{`${i18n.t("qrcode.goToQrcodes")} >>`}</a>
                    </>
                  </>
                )}
              </Form.Item>
              <Form.Item
                name="region_id"
                label={i18n.t("qrcode.selectRegion")}
                extra={i18n.t("qrcode.regionRule")}
                wrapperCol={{ span: 8 }}
              >
                <Cascader options={regionData} showSearch />
              </Form.Item>
            </>
          )}
          {settings.lottery_shop_type != 5 && (
            <Form.Item
              label={i18n.t("lottery.validTime")}
              name="valid_time"
              wrapperCol={{ span: 5 }}
              rules={[
                { required: true, message: i18n.t("lottery.validWarning") },
              ]}
              extra={i18n.t("lottery.validRule")}
            >
              <InputNumber min={0} />
            </Form.Item>
          )}

          <Form.Item
            label={i18n.t("sales.status")}
            name="status"
            valuePropName="checked"
            wrapperCol={{ span: 5 }}
            initialValue={true}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          {settings.lottery_shop_type == 0 ||
          settings.lottery_shop_type == 5 ? null : (
            <>
              <Form.Item
                label={i18n.t("lottery.onSite")}
                name="exchange_on_site"
                valuePropName="checked"
                wrapperCol={{ span: 5 }}
                initialValue={true}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
              <Form.Item
                label={i18n.t("lottery.selfBuy")}
                name="user_buy_ticket"
                valuePropName="checked"
                wrapperCol={{ span: 5 }}
                initialValue={false}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </>
          )}

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              if (getFieldValue("user_buy_ticket")) {
                return (
                  <Form.Item
                    label={i18n.t("lottery.ticketPrice")}
                    name="ticket_price"
                    wrapperCol={{ span: 5 }}
                    rules={[
                      {
                        required: true,
                        message: i18n.t("lottery.ticketWarning"),
                      },
                    ]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                );
              }
              return null;
            }}
          </Form.Item>
          <Divider orientation="left">
            {i18n.t("main.sideMenus.lotteryTheme")}
          </Divider>
          <Form.Item
            name="type"
            label={i18n.t("lottery.type")}
            initialValue={"wheel"}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) =>
                setThemeType(
                  e.target.value === "wheel"
                    ? 0
                    : e.target.value === "nine"
                    ? 1
                    : 2
                )
              }
            >
              {Object.keys(areas).map((k) => (
                <Radio.Button key={areas[k].label} value={k}>
                  {areas[k].label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <List
            bordered
            grid={{
              gutter: 20,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 3,
            }}
            size="large"
            style={{ backgroundColor: "#fff" }}
            itemLayout="horizontal"
            dataSource={themeList.data}
            pagination={{
              current: themeList.current_page,
              pageSize: 10,
              total: themeList.total,
              showQuickJumper: true,
              onChange: (page) => onGetDataList(page),
            }}
            renderItem={(item) => (
              <List.Item title={i18n.getThemeNameByLocale(item, "name")}>
                <Card
                  onClick={() => {
                    setThemeId({ id: item.id, type: item.type });
                    setThemeType(item.type);
                  }}
                  hoverable
                  style={{
                    minWidth: 200,
                    maxWidth: 320,
                    flexDirection: "column",
                  }}
                  cover={
                    <img
                      style={{
                        width: "100%",
                        height: "300px",
                      }}
                      alt="example"
                      src={item.thumb}
                    />
                  }
                  actions={[
                    <CheckCircleTwoTone
                      twoToneColor={themeId.id === item.id ? "#419cb4" : "#eee"}
                      key="check"
                    />,
                  ]}
                >
                  <Card.Meta
                    style={{ fontSize: "10px", textAlign: "center" }}
                    description={i18n.getThemeNameByLocale(item, "name")}
                  />
                </Card>
              </List.Item>
            )}
          />
          <Divider orientation="left"></Divider>
          <Form.Item
            colon={false}
            label={i18n.t("lottery.zhDesc")}
            name="zh_description"
            wrapperCol={{ span: 13 }}
          >
            <BraftEditor media={{ uploadFn: braftUpload }} />
          </Form.Item>
          <Form.Item
            colon={false}
            label={i18n.t("lottery.ugDesc")}
            name="ug_description"
            wrapperCol={{ span: 13 }}
          >
            <BraftEditor
              contentStyle={{ fontFamily: "Alp Ekran" }}
              media={{ uploadFn: braftUpload }}
            />
          </Form.Item>
          <Divider orientation="left" plain>
            {i18n.t("lottery.prizes")}
          </Divider>
          <Form.List name="items">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field) => {
                    const { action } = location.state;
                    const disabled =
                      action !== "copy" &&
                      form.getFieldValue("items")[field.key] &&
                      form.getFieldValue("items")[field.key].id;
                    return (
                      <div key={field.key}>
                        <Space direction="vertical">
                          <Space direction="horizontal">
                            <Form.Item noStyle shouldUpdate>
                              {() => (
                                <Form.Item
                                  {...field}
                                  label={i18n.t("lottery.itemType")}
                                  labelCol={{
                                    span: i18n.isLocaleRTL() ? 10 : 9,
                                  }}
                                  name={[field.name, "itemType"]}
                                  fieldKey={[field.fieldKey, "itemType"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: i18n.t(
                                        "lottery.itemTypeWarning"
                                      ),
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={disabled}
                                    style={{ width: 130 }}
                                  >
                                    {lotteryPrizeTypes.map((i) => (
                                      <Select.Option
                                        key={i.name}
                                        value={i.value}
                                      >
                                        {i.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              )}
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                              {() => {
                                const subType =
                                  form.getFieldValue("items")[field.key] &&
                                  form.getFieldValue("items")[field.key]
                                    .itemType;
                                if (
                                  subType === TYPES.PRODUCT ||
                                  subType === TYPES.PRODUCT_ON_SITE
                                ) {
                                  const emptylist =
                                    form.getFieldValue("items")[field.key]
                                      .productItem &&
                                    form.getFieldValue("items")[field.key]
                                      .productItem.length > 0;
                                  return (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "productItem"]}
                                      fieldKey={[field.fieldKey, "productItem"]}
                                      label={i18n.t("lottery.product")}
                                      labelCol={{
                                        span: i18n.isLocaleRTL() ? 8 : 5,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: i18n.t(
                                            "showsEdit.selectProduct"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Select
                                        disabled={disabled}
                                        mode="multiple"
                                        style={{ width: 180 }}
                                        placeholder={i18n.t(
                                          "showsEdit.selectProduct"
                                        )}
                                        notFoundContent={
                                          fetching ? (
                                            <Spin size="small" />
                                          ) : null
                                        }
                                        filterOption={false}
                                        onSearch={
                                          onSearchProduct &&
                                          onSearchProduct.current
                                        }
                                        tagRender={({
                                          closable,
                                          onClose,
                                          value,
                                        }) => {
                                          return (
                                            <Tag
                                              closable={closable}
                                              onClose={onClose}
                                              style={{ borderWidth: 0 }}
                                            >
                                              {value}
                                            </Tag>
                                          );
                                        }}
                                      >
                                        {!emptylist
                                          ? productList.data.map((d) => (
                                              <Select.Option
                                                key={d.id}
                                                value={d.id}
                                              >
                                                <Avatar
                                                  src={d.images[0].url}
                                                  shape="square"
                                                  size="large"
                                                />
                                                {i18n.getPropNameByLocale(
                                                  d,
                                                  "name"
                                                )}
                                              </Select.Option>
                                            ))
                                          : null}
                                      </Select>
                                    </Form.Item>
                                  );
                                }
                                if (
                                  subType === TYPES.RED ||
                                  subType === TYPES.RED_PACKET
                                ) {
                                  return (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "redItem"]}
                                      fieldKey={[field.fieldKey, "redItem"]}
                                      label={
                                        subType === TYPES.RED
                                          ? i18n.t("lottery.red")
                                          : i18n.t("qrcode.redPacket")
                                      }
                                      labelCol={{
                                        span: i18n.isLocaleRTL() ? 12 : 8,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            subType === TYPES.RED
                                              ? i18n.t("lottery.redWarning")
                                              : i18n.t(
                                                  "qrcode.redPaketWarning"
                                                ),
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled={disabled}
                                        formatter={(v) => `¥${v}`}
                                        max={200}
                                      />
                                    </Form.Item>
                                  );
                                }
                                if (subType === TYPES.POINT) {
                                  return (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "pointItem"]}
                                      fieldKey={[field.fieldKey, "pointItem"]}
                                      label={i18n.t("qrcode.point")}
                                      labelCol={{
                                        span: i18n.isLocaleRTL() ? 12 : 8,
                                      }}
                                      rules={[
                                        {
                                          required: true,
                                          message: i18n.t(
                                            "qrcode.pointWarning"
                                          ),
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        disabled={disabled}
                                        formatter={(v) => `${v}`}
                                        max={2000}
                                      />
                                    </Form.Item>
                                  );
                                }
                              }}
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                              {() => {
                                let subType =
                                  form.getFieldValue("items")[field.key] &&
                                  form.getFieldValue("items")[field.key]
                                    .itemType;
                                if (subType === TYPES.PRODUCT) {
                                  return (
                                    <Form.Item
                                      {...field}
                                      name={[field.name, "ship_price"]}
                                      fieldKey={[field.fieldKey, "ship_price"]}
                                      label={i18n.t("commissionList.ship")}
                                    >
                                      <InputNumber min={0} />
                                    </Form.Item>
                                  );
                                }
                              }}
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label={i18n.t("sales.coupons.quantity")}
                              labelCol={{
                                span: i18n.isLocaleRTL() ? 8 : 10,
                              }}
                              name={[field.name, "quantity"]}
                              fieldKey={[field.fieldKey, "quantity"]}
                            >
                              <InputNumber
                                disabled={
                                  settings.enable_lottery_edit
                                    ? false
                                    : disabled
                                }
                              />
                            </Form.Item>
                          </Space>
                          <Form.Item
                            {...field}
                            label={i18n.t("sales.nav.order")}
                            name={[field.name, "order"]}
                            fieldKey={[field.fieldKey, "order"]}
                          >
                            <InputNumber min={0} />
                          </Form.Item>
                          <Form.Item
                            label={i18n.t("qrcode.specialPrize")}
                            name="is_special_prize"
                            valuePropName="checked"
                            wrapperCol={{ span: 5 }}
                            initialValue={false}
                          >
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                            />
                          </Form.Item>
                          <Space direction="horizontal">
                            {[
                              {
                                label: i18n.t("sales.ad.zhImg"),
                                name: "zh_image",
                              },
                              {
                                label: i18n.t("sales.ad.ugImg"),
                                name: "ug_image",
                              },
                              {
                                label: i18n.t("lottery.zhAwardImg"),
                                name: "zh_award_image",
                              },
                              {
                                label: i18n.t("lottery.ugAwardImg"),
                                name: "ug_award_image",
                              },
                            ].map((item) => (
                              <Form.Item noStyle shouldUpdate key={item.label}>
                                {() => {
                                  let currentFiledValue = [];
                                  if (form.getFieldValue("items")[field.key]) {
                                    currentFiledValue =
                                      form.getFieldValue("items")[field.key][
                                        item.name
                                      ];
                                  }
                                  return (
                                    <Form.Item
                                      {...field}
                                      label={item.label}
                                      name={[field.name, item.name]}
                                      fieldKey={[field.fieldKey, item.name]}
                                      valuePropName="fileList"
                                      getValueFromEvent={(e) =>
                                        util.normFile4Img(e)
                                      }
                                    >
                                      <Upload
                                        accept="image/*"
                                        multiple={false}
                                        listType="picture-card"
                                        beforeUpload={onBeforeUpload}
                                        customRequest={onUploadImg}
                                      >
                                        {!(
                                          currentFiledValue &&
                                          currentFiledValue.length > 0
                                        ) && (
                                          <span>
                                            <UploadOutlined />
                                            {i18n.t("sales.upload")}
                                          </span>
                                        )}
                                      </Upload>
                                    </Form.Item>
                                  );
                                }}
                              </Form.Item>
                            ))}
                          </Space>
                          <Button
                            danger
                            type="primary"
                            onClick={() => remove(field.name)}
                          >
                            {i18n.t("product.productItem.actionDelete")}
                          </Button>
                        </Space>
                        <Divider />
                      </div>
                    );
                  })}
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const { action } = location.state;
                      return (
                        <Form.Item>
                          <Button
                            disabled={
                              action !== "copy" &&
                              areas[form.getFieldValue("type")] &&
                              areas[form.getFieldValue("type")].max ===
                                fields.length
                            }
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                          >
                            <PlusOutlined /> {i18n.t("lottery.add")}
                          </Button>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {i18n.t("sales.submit")}
            </Button>
            {!data?.id && (
              <Button
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={onSave2Local}
              >
                {i18n.t("sales.save")}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
};

export default LotteryEdit;
