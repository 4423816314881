import { Modal, Spin, Table } from "antd";
import React, { useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import {
  getList,
  remove,
  create,
  update,
} from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./CategoryList.scss";
import { categoryListColumns } from "./categoryListColumns";
import CategoryListHeader from "./CategoryListHeader";
import AddOrEditCategory from "./AddOrEditCategory";
const i18n = Locale.getInstance();

const CategoryList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const categoryFormRef = useRef();

  const selectCategory = (item) => {
    setCategory(item);
    const values = {
      parent_course_category_id: item?.parent_course_category_id,
      zh_name: item?.zh_name,
      ug_name: item?.ug_name,
      order: item?.order,
      status: !!item?.status,
      recommend: item?.recommend === 1,
    };

    setTimeout(() => {
      categoryFormRef.current?.setFieldsValue(values);
    }, 100);

    setOpenEditModal(true);
  };
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleSave = () => {
    const values = categoryFormRef.current.getFieldsValue();

    setLoading(true);
    const params = {
      data: values,
      token: user.token,
    };

    if (category) {
      updateCategoryMutation.mutate({
        resource: "course/categories",
        params: { ...params, id: category.id },
      });
    } else {
      addCategoryMutation.mutate({ resource: "course/categories", params });
    }
  };

  const addCategoryMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-categories");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      setOpenEditModal(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setOpenEditModal(false);
      setLoading(false);
    },
  });

  const updateCategoryMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-categories");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setOpenEditModal(false);
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setOpenEditModal(false);
      setLoading(false);
    },
  });

  const { data, error, isLoading } = useQuery(
    ["course-categories", user.token],
    () =>
      getList("course/categories", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },

        token: user.token,
      })
  );
  if (error) {
    showNotificationWithIcon(error.data.message);
  }
  const breadCrumbData = [
    {
      href: "/main/courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/course/categories",
      title: i18n.t("course.category.title"),
    },
  ];

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-categories");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });
  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="category">
        <CategoryListHeader selectCategory={selectCategory} />
        <Table
          childrenColumnName="sub_categories"
          expandable={{ defaultExpandAllRows: true }}
          className="list"
          rowKey="id"
          columns={categoryListColumns(
            deleteMutation,
            user.token,
            setLoading,
            selectCategory
          )}
          dataSource={data?.data}
          scroll={{
            y: 320,
          }}
          pagination={{
            onChange: handleChangePage,
            total: data?.total,
          }}
        />
      </div>
      <Modal
        title={
          category
            ? i18n.t("course.category.edit")
            : i18n.t("course.category.add")
        }
        open={openEditModal}
        onOk={handleSave}
        onCancel={() => {
          setOpenEditModal(false);
        }}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <AddOrEditCategory
          ref={categoryFormRef}
          loading={loading}
          category={category}
        />
      </Modal>
    </>
  );
};

export default CategoryList;
