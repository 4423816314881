import { Button, Spin, Table } from "antd";
import { useState } from "react";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";
import { getList } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";

import "./CourseCategorySelector.scss";

const i18n = Locale.getInstance();

const CourseCategorySelector = ({ setSelectedLink, selectedLink }) => {
  const user = useSelector((state) => getUserInfo(state));
  const [page, setPage] = useState(1);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const { data, error, isLoading } = useQuery(
    ["course-categories", user.token],
    () =>
      getList("course/categories", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },

        token: user.token,
      })
  );

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const columns = [
    {
      title: i18n.t("product.cats.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: i18n.t("product.cats.actions"),
      width: 100,
      render: (item) => {
        let url = `/course/category/index?categoryId=${item.id}`;
        if (item.parent_course_category_id) {
          url = `/course/category/index?categoryId=${item.parent_course_category_id}&subCategoryId=${item.id}`;
        }
        return (
          <>
            <Button
              type={item.id === selectedLink?.key ? "primary" : "default"}
              onClick={() => {
                setSelectedLink({
                  key: item.id,
                  ug_name: `دەرس تۈرى~${item.ug_name}`,
                  zh_name: `课程分类~${item.zh_name}`,
                  url,
                });
              }}
              size="small"
            >
              {item.id === selectedLink?.key
                ? i18n.t("link.selected")
                : i18n.t("link.select")}
            </Button>
            {item.id === selectedLink?.key ? (
              <Button
                type="default"
                onClick={() => {
                  setSelectedLink(false);
                }}
                size="small"
              >
                {i18n.t("link.deSelect")}
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="product-categories">
          <Table
            className="list"
            rowKey="id"
            childrenColumnName="sub_categories"
            expandable={{ defaultExpandAllRows: true }}
            columns={columns}
            dataSource={data?.data}
            pagination={{
              onChange: handleChangePage,
              total: data?.total,
            }}
            scroll={{
              y: 320,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default CourseCategorySelector;
