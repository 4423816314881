import { UserOutlined } from "@ant-design/icons";
import { Button, Modal, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";

import "./LinkSelector.scss";
import linkTabsData from "./linkTabsData";

const i18n = Locale.getInstance();

const LinkSelector = ({ selectedLink, setSelectedLink, items }) => {
  const [openLinkSelector, setOpenLinkSelector] = useState(false);
  const [link, selectLink] = useState(false);
  const settings = useSelector((state) => getMerchantSettings(state));

  if (!items) {
    items = [];
    items.push("platformLinks");
    items.push("products");
    items.push("productCategories");
    if (settings.enable_article) {
      items.push("articles");
    }
    if (settings.enable_course) {
      items.push("courses");
      items.push("courseCategories");
    }
    items.push("weapp");
    items.push("web");
  }
  useEffect(() => {
    if (selectedLink) {
      selectLink(selectedLink);
    }
  }, [selectedLink]);

  const handleFinishSelection = () => {
    setSelectedLink(link);
    setOpenLinkSelector(false);
  };
  const hideModal = () => {
    setOpenLinkSelector(false);
  };

  return (
    <>
      <div className="link">
        {link && link.zh_name ? (
          <>
            {link.image ? (
              <img src={link.image} alt={link.name} width={60} height={60} />
            ) : null}
            <Button
              onClick={() => {
                setOpenLinkSelector(true);
              }}
            >
              {i18n.getPropNameByLocale(link, "name")}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              setOpenLinkSelector(true);
            }}
          >
            {i18n.t("link.select")}
          </Button>
        )}
      </div>
      <Modal
        title={i18n.t("link.title")}
        open={openLinkSelector}
        onOk={handleFinishSelection}
        onCancel={hideModal}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <Tabs
          defaultActiveKey="1"
          tabPosition={"left"}
          style={{ height: 420 }}
          items={linkTabsData(link, selectLink, items)}
        />
      </Modal>
    </>
  );
};

export default LinkSelector;
